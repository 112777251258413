import { render, staticRenderFns } from "./StatusType.vue?vue&type=template&id=06965935&"
import script from "./StatusType.vue?vue&type=script&lang=js&"
export * from "./StatusType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports